
import PortfolioIconGenerator, { PORTFOLIO_ICONS_KEYS } from '@/components/portfolio/icons/PortfolioIconGenerator'
import PortfolioService from '@/service/PortfolioService'
import Alert from "@/components/Alert";

export const UPLOAD_ICON_STATUSES = {
    none: 0,
    initial: 1,
    uploading: 2,
    fail: 3,
    success: 4
}
const MAX_ICON_SIZE = 100 * 1024; // 100kb
const NUMBER_VISIBLE_ICON_DEFAULT = 13;

export default {
    name: 'CustomPortfolioIcon',
    components: {
        PortfolioIconGenerator,
        Alert
    },

    props: {
        status: {
            type: Number,
            default: UPLOAD_ICON_STATUSES.none
        },
        selectedIconKey: {
            type: String,
            default: ''
        },
        uploadedIcons: {
            type: Array,
            default: () => []
        },
        selectedCustomIconId: {
            type: [Number, String],
            default: null
        },
        initSelectedIconKey: {
            type: String,
            default: ''
        },
    },

    data() {
        return {
            showedMore: false,
            uploadIconStatuses: UPLOAD_ICON_STATUSES,
            file: null,
            errorMessage: '',
            dragover: false,
            isDeleteDialogVisible: false,
            isDeleting: false
        }
    },

    computed: {
        portfolioIconKeys() {
            const iconKeys = [...PORTFOLIO_ICONS_KEYS]
            if (this.initSelectedIconKey) {
                const index = iconKeys.indexOf(this.initSelectedIconKey);
                if (index > -1) {
                    iconKeys.splice(index, 1);
                    iconKeys.unshift(this.initSelectedIconKey)
                }
            }

            return this.showedMore ? iconKeys : iconKeys.slice(0, NUMBER_VISIBLE_ICON_DEFAULT)
        },
        uploadIcon() {
            return this.$vuetify.theme.dark ?
                require('@/assets/add_porfolio/dark/upload-icon.svg') :
                require('@/assets/add_porfolio/light/upload-icon.svg');
        },
        uploading() {
            return this.status === UPLOAD_ICON_STATUSES.uploading
        },
        hasCustomIcon() {
            return this.uploadedIcons?.length > 0
        }
    },

    watch: {
        status(curStatus) {
            if (curStatus !== UPLOAD_ICON_STATUSES.fail) {
                this.errorMessage = null
            }
        }
    },

    methods: {
        handleClickIcon(key) {
            if (this.selectedIconKey === key) {
                // deselect icon
                this.$emit('update:iconKey', '')
            } else {
                this.$emit('update:iconKey', key)
            }
        },
        handleClickCustomIcon() {
            this.$emit('update:status', UPLOAD_ICON_STATUSES.initial)
        },
        selectedFileChange(file) {
            this.file = file
            if (file) {
                this.handleUploadedFile()
            }
        },
        onDrop(e) {
            this.dragover = false
            const files = Array.from(e.dataTransfer.files)
            this.file = files[0]
            if (this.file) {
                this.handleUploadedFile()
            }
        },
        validateFile() {
            if (!this.file) {
                return
            }

            if (!['image/png', 'image/svg+xml', 'image/jpeg'].includes(this.file.type)) {
                return this.$t('component.portfolio.addPortfolio.unsupportedFile')
            }

            if (this.file.size > MAX_ICON_SIZE) {
                return this.$t('component.portfolio.addPortfolio.fileTooLarge')
            }
    
            return ''
        },
        async handleDeleteCustomIcon(id) {
            this.$emit('update:deletedIcon', id)
        },
        async handleUploadedFile() {
            this.errorMessage = this.validateFile()
            if (this.errorMessage !== '') {
                return
            }
            const src = URL.createObjectURL(this.file)
            const id = (new Date().getTime()).toString(36) // generate an unique id
            const iconData = { file: this.file, src, id }
            this.$emit('update:newUploadedIcon', iconData)
            this.$emit('update:status', UPLOAD_ICON_STATUSES.none)
        },
        handleSelectCustomIcon(id) {
            this.$emit('update:selectedCustomIcon', id)
        }
    },

    created() {
        this.portfolioService = new PortfolioService(this.$axios)
    }
}


    import CsvImportService from '@/service/CsvImportService'
    import FormatterService from '@/service/FormatterService'
    import TransactionService from '@/service/TransactionService'
    import Alert from "@/components/Alert"
    import { backendExceptionHandler } from '@/mixins';
    import { sortItems } from 'vuetify/es5/util/helpers'

    const INLINE_EDIT_MAX_LENGHT = {
        qty: 8,
        price: 10,
        date: 10,
        brokerTransactionId: 20,
    }

    const POSITIVE_FIELDS = ['price']

    const NON_FALSY_FIELDS = ['isin', 'qty', 'date', 'currency', 'mic', 'type']
    
    const EXTRACT_STATUS = {
        complete: 'complete',
        progressing: 'progressing',
        aborted: 'aborted'
    }

    export default {
        name: 'ImportPortfolioPositions',
        components: { Alert },
        mixins: [ backendExceptionHandler ],
        props: [
            'portfolioId',
        ],

        data() {
            return {
                docImportId: null,
                errorTransactionsCount: 0,
                importedTransactions: 0,
                format: new FormatterService(),
                isLoading: false,
                isVisible: false,
                uploadProgress: 100,
                completedTransactionsCount: 0,
                errorMessage: '',
                files: [],
                dragover: false,
                uploadResults: [],
                selectedImports: [],
                logos: {},
                headers: [
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.transactionId'), value: 'brokerTransactionId', type: 'string' },
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.broker'), value: 'brokerId', type: 'select' },
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.type'), value: 'type', type: 'select' },
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.isin'), value: 'isin', type: 'search' },
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.name'), value: 'name' },
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.mic'), value: 'mic', type: 'autocomplete' },
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.qty'), value: 'qty', type: 'decimal' },
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.price'), value: 'price', type: 'decimal' },
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.currency'), value: 'currency', type: 'autocomplete' },
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.totalPrice'), value: 'total', type: 'decimal' },
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.purchaseDate'), value: 'date', type: 'date' },
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.costs'), value: 'costs', type: 'decimal' },
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.costsCurrency'), value: 'costsCurrency', type: 'autocomplete' },
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.taxes'), value: 'taxes', type: 'decimal' },
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.taxesCurrency'), value: 'taxesCurrency', type: 'autocomplete' },
                    { text: this.$t('component.portfolio.importPortfolioPositions.headers.importSource'), value: 'importSource' }
                ].map(h => {
                    const editable = !['total', 'name'].includes(h.value)
                    return { ...h, editable, cellClass: editable ? 'editable' : null }
                })
                .map(h => ({ ...h, align: ['date', 'decimal'].includes(h.type) ? 'right' : null })),
                menuState: { visible: false, loading: false, x: 0, y: 0, value: null, header: null, item: null, type: null, items: [], search: null, field: '' },
                hideProgress: true,
                showLogos: true,
                isExtracting: false,
                extractingProgress: {
                    processed: 0,
                    total: 0,
                    transactionsExtracted: 0,
                    status: EXTRACT_STATUS.progressing
                },
                uploadingProgress: {
                    processed: 0,
                    total: 0
                },
                isCanceled: false,
            }
        },

        computed: {
            hasErrors() {
                return this.selectedImports.some(row => row.errors.length)
            },
            uploadErrors() {
                const errors = this.uploadResults.reduce((prev, curr) => {
                    if (curr.errorMessage) {
                        prev.push(`${curr.src}: ${curr.errorMessage}`)
                    }
                    return prev
                }, [])
                return errors
            },
            typeMapping() {
                return  {
                    'buy': this.$t('Kauf'),
                    'sell': this.$t('Verkauf'),
                    'dividend': this.$t('Dividende'),
                    'deposit': this.$t('Einbuchung'),
                    'withdrawal': this.$t('Ausbuchung'),
                    'acquisition-data': this.$t('Anschaffungsdaten'),
                }
            }
        },

        methods: {
            init() {
                this.getAllSelectData()
                this.reset()
                this.isVisible = true
                this.uploadProgress = 0
            },

            untransformData(data, keepIds = false) {
                return data.map(row => {
                    const result = Object.assign({}, row)
                    if (!keepIds) {
                        delete result['id']
                    }
                    delete result['errors']
                    Object.keys(row).forEach(k => {
                        const type = this.headers.find(h => h.value == k)?.type
                        switch (type) {
                            case 'decimal':
                                result[k] = this.format.parseLocaleNumber(row[k], "de")
                                break
                            case 'select':
                            case 'autocomplete':
                                result[k] = this.getSelectData(k).find(b => b.text == row[k])?.value
                        }
                    })
                    return result
                })
            },

            transformData(data, keepIds = false) {
                return data.map((row, i) => {
                    const result = Object.assign({}, row)
                    if (!keepIds) {
                        result['id'] = i
                    }
                    result['errors'] = row['errors'] || []
                    result['version'] = 1
                    Object.keys(row).forEach(k => {
                        const type = this.headers.find(h => h.value == k)?.type
                        switch (type) {
                            case 'decimal':
                                result[k] = this.format.currency(row[k], false, 'Import') || '0'
                                break
                            case 'select':
                            case 'autocomplete':
                                result[k] = this.getSelectData(k).find(b => b.value == row[k])?.text
                        }
                    })
                    return result
                })
            },

            customSort(_items, sortBy, sortDesc, locale, customSorters) {
                const itemsToSort = this.uploadResults
                if (!sortBy[0]) {
                    return itemsToSort;
                }
                const sortable = this.untransformData(itemsToSort, true).map(item => {
                    item['type.original'] = item['type']
                    item.type = this.typeMapping[item['type.original']]

                    return item
                })
                const sorted = sortItems(sortable, sortBy, sortDesc, locale, customSorters)
                    .map(item => {
                        item['type'] = item['type.original']
                        delete item['type.original']

                        return item
                    })

                const sortedTransformed = this.transformData(sorted, true)
                this.validateTransactions(sortedTransformed, sortable)

                return sortedTransformed
            },

            menuClickedOutside(e) {
                if (e.target.hasAttribute('data-app')) {
                    // I had to add this for the broker select to work, i don't know why
                    return
                }
                if (e.target.contentEditable != 'true' && !e.target.closest('[role=menu]')) {
                    this.menuState.visible = false
                }
            },

            onInlineEditableHandler(item, header) {
                switch (header.type) {
                    case 'date':
                        return {
                            focus: event => this.onInlineDateEditingFocus(event, item, header),
                            blur: event => this.onInlineDateEditingBlur(event, item, header),
                            keyup: event => this.onInlineDateEditingEvent(event, item, header),
                            keypress: event => this.onInlineDateEditingEvent(event, item, header),
                        }
                    case 'select':
                    case 'autocomplete':
                        return {
                            focus: event => this.onInlineSelectEditingFocus(event, item, header),
                            blur: event => this.onInlineSelectEditingBlur(event, item, header),
                            keyup: event => event.preventDefault(),
                            keydown: event => event.preventDefault(),
                        }
                    case 'search':
                        return {
                            focus: event => this.onInlineSearchEditingFocus(event, item, header),
                            blur: event => this.onInlineSelectEditingBlur(event, item, header),
                            keyup: event => event.preventDefault(),
                            keydown: event => event.preventDefault(),
                        }
                    case 'decimal':
                        return {
                            focus: event => this.onInlineEditing(event, item, header),
                            blur: event => this.onInlineEditingEvent(event, item, header),
                            keyup: event => this.onInlineEditingEvent(event, item, header),
                            keypress: event => this.onInlineEditingEvent(event, item, header),
                        }
                    case 'string':
                        return {
                            focus: event => this.onInlineEditing(event, item, header),
                            blur: event => this.onInlineStringEditingBlur(event, item, header),
                            keyup: event => this.onInlineEditingEvent(event, item, header),
                            keypress: event => this.onInlineEditingEvent(event, item, header),
                        }
                }
            },

            getSelectData(field) {
                switch (field) {
                    case 'brokerId':
                        return this.brokers?.map(b => ({ value: b.id, text: b.name }))
                    case 'mic':
                        return this.mics?.map(mic => ({ value: mic?.mic, text: mic?.mic }))
                    case 'currency':
                    case 'costsCurrency':
                    case 'taxesCurrency':
                        return this.currencies?.map(c => {
                            if (c?.header) {
                                return { header: c.header }
                            }
                            return { value: c?.currency, text: c?.currency, name: c?.name }
                        })
                    case 'type':
                        return Object
                            .keys(this.typeMapping)
                            .map(k => ({ value: k, text: this.typeMapping[k] }))
                }
            },

            async getSearchData(field, term = null) {
                let searchData
                switch (field) {
                    case 'isin':
                        searchData = await this.csvImportService.searchShares(term)
                        return searchData?.data.map(d => ({ value: d.isin, text: `${d.name} <${d.isin}>`, name: d.name }))
                    case 'mic':
                        return this.menuState.items
                    case 'currency':
                        return this.menuState.items
                }
            },

            onInlineEditing(event, item, header) {
                event.target.closest('td').classList.add('editing')
                this.menuState.type = header.type
                this.menuState.item = item
                this.menuState.header = header
                const rect = event.target.getBoundingClientRect()
                this.menuState.x = rect.left - 3
                this.menuState.y = rect.top
                this.menuState.visible = false
                this.menuState.field = header.value
            },

            onInlineDateEditingFocus(event, item, header) {
                this.onInlineEditing(event, item, header)
                this.menuState.value = this.format.germanToIsoDate(event.target.textContent.trim())
                this.menuState.visible = true
            },

            onInlineDateEditingEvent(event, item, header) {
                if (event.type == 'keypress' && event.key == "Enter") {
                    event.preventDefault()
                } else if (event.type == 'keypress' && event.key != "Enter"
                        && event.target.textContent.match(/\d/g).length > INLINE_EDIT_MAX_LENGHT[header.updateRequestField]) {
                    event.preventDefault()
                } else if (event.key == "Enter") {
                    event.target.blur()
                }
            },

            onInlineSelectEditingFocus(event, item, header) {
                this.onInlineEditing(event, item, header)
                this.menuState.items = this.getSelectData(header.value)
                this.menuState.value = this.menuState.items.find(e => e.text == event.target.textContent)?.value
                this.menuState.visible = true
            },

            async onInlineSearchEditingFocus(event, item, header) {
                this.onInlineEditing(event, item, header)
                this.menuState.value = null
                this.menuState.search = null
                this.menuState.items = await this.getSearchData(header.value)
                this.menuState.visible = true
            },

            onInlineEditingBlur(event, item, header, newValue = null) {
                const original = item[header.value]
                event.target.closest('td').classList.remove('editing')
                if (!newValue || original === event.target.textContent.trim()) {
                    return  // no update is needed because the date does not change
                }
                item[header.value] = newValue  // update item
                this.validateTable()
                this.menuState.visible = false
            },

            onInlineDateEditingBlur(event, item, header) {
                const newValue = this.format.germanToIsoDate(event.target.textContent.trim())
                this.onInlineEditingBlur(event, item, header, newValue)
            },

            onInlineSelectEditingBlur(event, item, header) {
                const newValue = event.target.textContent.trim()
                if (this.menuState.items.find(e => e.text == newValue)) {
                    this.onInlineEditingBlur(event, item, header, newValue)
                } else {
                    this.onInlineEditingBlur(event, item, header)
                }
            },

            onInlineSelectChanged(value) {
                if (this.menuState?.header.value == 'isin') {
                    this.menuState.item['name'] = this.menuState.items.find(e => e.value == value)?.name.substr(0, 32)
                    return this.onInlineFieldChanged(value)
                }
                if (this.menuState?.header.value == 'type') {
                    const absQty = Math.abs(this.format.parseLocaleNumber(this.menuState.item.qty, 'de'))
                    const absTotal = Math.abs(this.format.parseLocaleNumber(this.menuState.item.total, 'de'))
                    if (absQty != 0) {
                        switch (value) {
                            case 'buy':
                            case 'dividend':
                                this.menuState.item.qty = this.format.currency(absQty, false, 'Import')
                                this.menuState.item.total = this.format.currency(absTotal, false, 'Import')
                                break
                            case 'sell':
                                this.menuState.item.qty = this.format.currency(-absQty, false, 'Import')
                                this.menuState.item.total = this.format.currency(-absTotal, false, 'Import')
                                break
                        }
                    }
                }
                return this.onInlineFieldChanged(this.menuState.items.find(e => e.value == value)?.text)
            },

            onInlineFieldChanged(value) {
                const item = this.menuState.item
                const header = this.menuState?.header
                if (value == item[header.value]) {
                    return  // no update is needed because the date does not change
                }
                
                item[header.value] = value  // update item
                item.version++
                this.menuState.visible = false
                const index = this.uploadResults.findIndex(r => r.id === item.id)                
                this.uploadResults[index] = item

                this.validateTable()
            },

            async onInlineEditingEvent(event, item, header) {
                this.hasInlineEditingError = false
                if (event.type == 'keypress' && event.key == "Enter") {
                    event.preventDefault()
                } else if (event.type == 'keypress' && event.key != "Enter"
                        && event.target.textContent.length > INLINE_EDIT_MAX_LENGHT[header.updateRequestField]) {
                    event.preventDefault()
                } else if (event.key == "Enter") {
                    event.target.blur()
                } else if (event.type == 'blur') {  // date is not be handled here, only qty and price
                    event.target.closest('td').classList.remove('editing')
                    const rawValue = event.target.textContent.trim()
                    let newValue
                    if (/^-?[0-9]{1,3}(\.?[0-9]{3})*(,[0-9]*)*$/.test(rawValue)) {
                        newValue = this.format.parseLocaleNumber(rawValue, 'de')
                    }
                    if (newValue == undefined || POSITIVE_FIELDS.includes(header.value) && newValue < 0) {
                        this.errorMessage = `${this.$t('component.portfolio.importPortfolioPositions.errors.invalidNumber')} ${rawValue}`
                        this.hasInlineEditingError = true
                        event.target.textContent = item[header.value]
                        return
                    }
                    if (newValue == item[header.value]) {
                        return  // no update is needed because nothing changed
                    }
                    item[header.value] = this.format.currency(newValue, false, 'Import')  // update item
                    item.version++
                    if (['qty', 'price'].includes(header.value)) {
                        const total = this.format.parseLocaleNumber(item['qty'], 'de') * this.format.parseLocaleNumber(item['price'], 'de')
                        item['total'] = this.format.currency(total, false, 'Import')
                    }
                    if (header.value == 'qty' && [this.$t('Kauf'), this.$t('Verkauf')].includes(item.type)) {
                        if (this.format.parseLocaleNumber(item['qty'], 'de') < 0) {
                            item.type = this.$t('Verkauf')
                        } else {
                            item.type = this.$t('Kauf')
                        }
                    }
                    this.validateTable()
                } else if (event.key == 'Escape') {
                    event.target.textContent = item[header.value]
                    this.menuState.visible = false
                    event.target.blur()
                }
            },

            onInlineStringEditingBlur(event, item, header) {
                event.target.closest('td').classList.remove('editing')
                const newValue = event.target.textContent.trim()
                if (newValue == item[header.value]) {
                    return  // no update is needed because nothing changed
                }
                item[header.value] = newValue  // update item
                item.version++
            },

            selectFile(files) {
                this.files = files
                if (files && files.length) {
                    this.upload()
                }
            },

            validateTable() {
                const unformatted = this.untransformData(this.uploadResults)
                const status = this.validateTransactions(
                    this.uploadResults,
                    unformatted
                )
                this.errorTransactionsCount = status.errorTransactionsCount
                this.importedTransactions = status.importedTransactions
            },

            validateTransactions(rows, data) {
                const status = {
                    errorTransactionsCount: 0,
                    importedTransactions: 0,
                }
                rows.forEach((row, i) => {
                    let isErrorInTransaction = false
                    row['errors'] = []
                    NON_FALSY_FIELDS.forEach(f => {
                        if (!data[i][f]) {
                            row['errors'].push(f)
                            isErrorInTransaction = true
                        }
                    })
                    POSITIVE_FIELDS.forEach(f => {
                        if (data[i][f] < 0) {
                            row['errors'].push(f)
                            isErrorInTransaction = true
                        }
                    })
                    if (isErrorInTransaction){
                        status.errorTransactionsCount++
                    }
                    status.importedTransactions++
                })

                return status;
            },

            validateFiles(files) {
                if (!files) {
                    return
                }
                let error = ''
                Array.from(files).forEach(file => {
                    if (!['text/csv', 'application/pdf', 'application/zip', 'application/vnd.ms-excel'].includes(file.type)) {
                        error = this.$t('component.portfolio.importPortfolioPositions.errors.expectedFileTypes') + file.type
                    }
                })
                return error
            },

            cancelUploading() {
                this.isLoading = false
                this.hideProgress = true;
            },

            async getDocImportId() {
                try {
                    const docImportRes = await this.csvImportService.getDocImportId()                    
                    const docImportId = docImportRes.data.docImportId
                    if (!docImportId) {
                        this.cancelUploading()
                        this.errorMessage = this.$t('component.portfolio.importPortfolioPositions.errors.genericError')
                        return null
                    }

                    return docImportId
                } catch (e) {
                    this.cancelUploading()
                    if(e.response?.data?.message) {
                      this.errorMessage = e.response.data.message
                    } else {
                      this.errorMessage = e.message || this.$t('component.portfolio.importPortfolioPositions.errors.genericError')
                    }
                    return null
                }
            },

            cancelActiveGetExtractStatusRequest() {
                if (this.activeGetExtractStatusRequest) {
                    this.activeGetExtractStatusRequest.cancel()
                }
            },

            cancelPollingExtractStatus() {
                this.cancelActiveGetExtractStatusRequest()                
                if (this.getExtractStatusInterval) {
                    clearInterval(this.getExtractStatusInterval)
                }            
            },

            async handleExtractCompleted() {
                this.cancelPollingExtractStatus()

                try {
                    const transactionsRes = await this.csvImportService.getTransactions(this.docImportId)                    
                    this.uploadResults = this.transformData(transactionsRes.data);
                    if(!this.uploadResults.length) {
                        this.errorMessage = this.$t('component.portfolio.importPortfolioPositions.errors.noTransactionsError');
                    }
                    this.selectedImports = this.uploadResults
                    this.validateTable()                    
                } catch (e) {
                    this.errorMessage = e.message || this.$t('component.portfolio.importPortfolioPositions.errors.noTransactionsError');
                } finally {
                    this.isLoading = false
                    this.hideProgress = true;
                }
            },

            handleExtractAborted() {
                this.cancelPollingExtractStatus()
                this.isLoading = false
                this.hideProgress = true
                this.errorMessage = this.$t('component.portfolio.importPortfolioPositions.errors.extractAborted')
            },

            async getExtractStatus() {
                this.cancelActiveGetExtractStatusRequest()

                const axiosSource = this.$axios.CancelToken.source();
                this.activeGetExtractStatusRequest = { cancel: axiosSource.cancel, msg: "" };

                try {
                    this.$axios
                        .get(`/api/import/${this.docImportId}/status`, { cancelToken: axiosSource.token })
                        .then((res) => {
                            this.activeGetExtractStatusRequest = null;
                            const { filesProcessed, filesTotal, transactionsExtracted, status } = res.data
                            this.extractingProgress = {
                                processed: filesProcessed,
                                total: filesTotal,
                                transactionsExtracted,
                                status
                            }
                            this.uploadProgress = 50 + 50 * (filesProcessed / filesTotal)
                            
                            if (status === EXTRACT_STATUS.complete) {                                
                                this.handleExtractCompleted()
                            }
                            if (status === EXTRACT_STATUS.aborted) {                                
                                this.handleExtractAborted()
                            }
                        })
                } catch (error) {
                    if (this.$axios.isCancel(error)) {
                        return
                    }
                }
            },

            startExtracting() {
                this.isExtracting = true
                this.getExtractStatusInterval = setInterval(this.getExtractStatus, 1500);
            },

            async upload() {
                this.updateErrorMessage()
                this.isCanceled = false
                if ((this.errorMessage = this.validateFiles(this.files)) !== '') {
                    return
                }
                
                this.isLoading = true
                this.docImportId = await this.getDocImportId()
                
                if (!this.docImportId){
                    return
                }
                this.$emit('update:status', 'uploading')
                const totalFile = this.files.length
                this.uploadingProgress = {
                    total: totalFile,
                    processed: 0
                }
                this.hideProgress = false;
                this.uploadProgress = 0
                const totalSize = this.files.reduce((prev, curr) => prev + curr.size, 0)
                let tempLoadedFileSize = 0

                for (const f of this.files) {
                    if (!this.isVisible || this.isCanceled) {                        
                        break  // dialog asynchronously closed or the user canceled import progress
                    }
                    try {
                        await this.csvImportService.postFiles(
                            [f],
                            this.docImportId,
                            (loadedFileSize, totalFileSize) => {
                                const lastestLoadedFileSize = loadedFileSize - tempLoadedFileSize
                                tempLoadedFileSize = loadedFileSize

                                this.uploadProgress += 50 * lastestLoadedFileSize / totalSize
                                if (loadedFileSize === totalFileSize) {
                                    tempLoadedFileSize = 0
                                    this.uploadingProgress.processed++
                                }
                            }
                        )
                    } catch (e) {
                        tempLoadedFileSize = 0
                        this.uploadingProgress.processed++
                    }
                }

                if (!this.isVisible || this.isCanceled) {
                    return
                }
                // the extracting progress should not start if the user canceled the import progres
                this.extractingProgress = {
                    processed: 0,
                    total: totalFile,
                    transactionsExtracted: 0,
                    status: EXTRACT_STATUS.progressing
                },
                this.startExtracting()
            },

            async commitImport() {
                if (!this.selectedImports.length) {
                    this.errorMessage = this.$t('component.portfolio.importPortfolioPositions.errors.noRowsSelected')
                    return
                }
                this.errorMessage = null
                this.isLoading = true
                this.uploadProgress = 0
                this.completedTransactionsCount = 0
                try {
                    await this.csvImportService.commitImport(
                        this.untransformData(this.selectedImports),
                        this.portfolioId,
                        this.docImportId,
                        (completedCommits) => {
                            this.completedTransactionsCount = completedCommits;
                            this.uploadProgress = (completedCommits / this.selectedImports.length) * 100
                        }
                    )
                } catch (e) {
                    this.errorMessage = e.message || this.$t('component.portfolio.importPortfolioPositions.errors.genericError')
                    console.log('errorMessage: ' + this.errorMessage)
                    this.$emit('update:status', 'error')
                    return
                } finally {
                    this.isLoading = false
                }

                this.errorMessage = null
                this.isLoading = true
                try {
                    await this.csvImportService.finalizeImport(this.portfolioId)
                } catch (e) {
                    this.errorMessage = e.response?.data.message || this.$t('component.portfolio.importPortfolioPositions.errors.finalizationError')
                    console.log(e)
                    this.$emit('update:status', 'error')
                    return
                } finally {
                    this.isLoading = false
                }

                this.$emit('update:status', 'success')
                this.$root.$emit('kpi-totals-data-updated')
            },

            reset() {
                this.errorMessage = ''
                this.files = []
                this.uploadResults = []
                this.uploadProgress = 0
                this.$refs['upload-form']?.reset()
                this.$emit('update:status', 'empty')
                this.isExtracting = false
                this.hideProgress = true
                this.isLoading = false
                this.docImportId = null
                this.cancelPollingExtractStatus()
            },

            onDrop(e) {
                this.dragover = false;
                this.files = Array.from(e.dataTransfer.files)
                this.upload()
            },
            importAll(r) {
                const imgs = {}
                r.keys().forEach(key => (imgs[key] = r(key)))
                this.logos = imgs
            },

            moveMicToTop(key) {
                const item = this.mics.find(item => item?.mic === key)
                this.mics.unshift(item)
            },

            async getAllSelectData() {
                if (this.brokers) {
                    return  // data is cached already
                }
                this.transactionService.getBrokers()
                    .then(brokers => this.brokers = brokers)
                this.csvImportService.getMics()
                    .then(mics => {
                        this.mics = mics
                        this.moveMicToTop('XETR')
                        this.moveMicToTop('LSSI')
                        this.moveMicToTop('XNAS')
                        this.moveMicToTop('XNYS')
                        this.moveMicToTop('XFRA')
                    })
                this.csvImportService.getCurrencies()
                    .then(currencies => {
                        const groupTitles = {others: this.$t('Weitere'), top: this.$t('Häufig verwendet')};
                        this.currencies = this.format.getFormattedCurrencies(currencies, groupTitles)
                    })
            },

            isNegative(value) {
                if (!value || !value.trim) {
                    return false
                }
                return value.trim().substr(0, 1) == '-'
            },

            checkReadiness() {
                if (this.selectedImports.length && !this.hasErrors) {
                    this.$emit('update:status', 'ready')
                } else if (!this.uploadResults.length) {
                    this.$emit('update:status', 'empty')
                    return
                } else if (this.hasErrors) {
                    this.$emit('update:status', 'error')
                    return
                }
            },
            updateErrorMessage() {
                this.errorMessage = '';
            },
            cancelImport() {                
                this.isCanceled = true
                this.reset()
            }
        },

        watch: {
            selectedImports() {
                this.checkReadiness()
            },
            hasErrors() {
                this.checkReadiness()
            },
            'menuState.search': async function(term) {
                if (this.menuState.search == this.menuState.text || !this.menuState.text && this.menuState.value) {
                    return
                }
                this.menuState.loading = true
                try {
                    const searchData = await this.getSearchData(this.menuState?.header?.value, term)
                    if (!searchData) {
                        return
                    }
                    this.menuState.items = searchData
                    this.menuState.search = term
                } catch (e) {
                    this.menuState.loading = false
                    return
                }
                this.menuState.loading = false
            },
            "$store.state.preferences.isPrivacyEnabled": {
                handler(value) {
                    this.format.updatePrivacyEnabled(value)
                },
                immediate: true
            },
            isVisible(visible) {
                if (!visible) {
                    this.cancelPollingExtractStatus()
                }
            }
        },

        created() {
            this.$on('commit-import', this.commitImport);
            this.$on('cancel-import', this.cancelImport);
            this.$on('reset', this.reset);
            this.csvImportService = new CsvImportService(this.$axios)
            this.transactionService = new TransactionService(this.$axios)
            this.importAll(require.context('../../assets/broker_logos'))
            this.init()
        },

        beforeDestroy() {
            this.$off();
        }
    }
